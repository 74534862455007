// Utilities
@import 'Variables';

.title-container {
    display: flex;
    flex-direction: column;
    text-align: center;

    .hero-header {
        margin-top: 1em;
        margin-bottom: .25em;
        font-size: 6.5rem;

    }

    .hero-subheader {
        margin-bottom: 2em;
        font-family: 'Raleway', sans-serif;
        font-size: 2rem;
        color: #808080;
    }

    @media screen and (max-width: $maxDeviceWidth) {

        .hero-header {
           font-size: 3.25rem;
        }

        .hero-subheader {
            font-size: 1.25rem;
         }
    
    }
    
}

.series-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;

    .icon-text-container {
        margin: 0;
        width: 20em;
        display: flex;
        padding: 1em;
        cursor: pointer;

        &:hover {
            
            .round-square {
                transition: fill .2s;
                fill: #C3DBFF;
            }
            
            .icon {
                fill: #4F74D4;
                transition: fill .2s;
            }
        
            h4 {
                color: #4F74D4;
                transition: fill .2s;
            }

        }

    }

    .icon-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        .round-square {
            transition: fill .2s;
            fill: #EAEAEA;
            width: 6em;
            height: 6em;
        }

        .icon {
            fill: black;
            position: absolute;
            width: 66%;
            height: 66%;
        }

        @media screen and (max-width: $maxDeviceWidth) {

            .round-square {
                width: 4em;
                height: 4em;
            }

        }
    
    }

    .text-container {
        padding-left: 1em;
        float: right;
        max-width: 15em;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        h4 {
            margin: 0;
        }

        body {
            margin: 0;
            color: #808080;
        }
    }

}