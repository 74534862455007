@import "Variables";

//--- NavBar Variables ---//
$linkColor: #9B9C9F;
$linkHoverColor: #C7C8C9;

//--- Navbar styling ---//
.navbar {
    background-color: #3A3C48;
    overflow: hidden;
    height: 55px;
    transition: height .4s ease;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;

    // Links
    a {
        transition: color .2s;
        padding: 16px 10px;
        text-decoration: none;
        color: $linkColor;
        font-size: 1rem;
        font-family: 'Cabin', sans-serif;

        &:first-child{
            color: white;
            font-size: 1.2rem;
        }

        &:hover {
            color: $linkHoverColor;
        }

    }

    // Hide hamburger menu when display width 
    // is greater than maximum device width
    .ham {
        display: none;
        fill: $linkColor;
        height: 20px;
        width: 20px;
        padding: 14px 16px;
        cursor: pointer;

        &:hover {
            fill: $linkHoverColor;
        }

    }

    // When display width is less than maximum device width
    @media screen and (max-width: $maxDeviceWidth) {

        flex-direction: column;
        align-items: flex-start;

        // Keep hamburger menu fixed on top right of screen
        .ham {
            display: block;
            position: absolute;
            right: 0px;
            top: 3px;
        }

        // When navbar div class is both "navbar" and "responsive"
        // which changes based on JS function "dropDownMenu"
        &.responsive {
            transition: height .4s ease;
            height: 160px;
            // Show links
            a {
                display: block;
            }
        }
    }
}