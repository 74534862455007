@import url('https://fonts.googleapis.com/css2?family=Cabin&family=Raleway:wght@100&display=swap');
@import "Variables";

body {
  // Removes default screen padding
  margin: 0;
  font-size: 1rem;
    
  // Cabin is a great font!
  font-family: 'Cabin', sans-serif;;
  color: $mainTextColor;
  // Configs for smoother text rendering
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-size: 5rem;
  color: #2E2E2E;
}

h2 {
  font-size: 4rem;
  color: #2E2E2E;
}

h3 {
  font-size: 3rem;
  color: #2E2E2E;
}

h4 {
  font-size: 2rem;
  color: #2E2E2E;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
